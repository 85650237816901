import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Candidat } from "../../interfaces/Candidat/Candidat";
import toast from "react-hot-toast";
const API_URL = "https://app.greenlinks.fr/api/candidat/";
const API_PARSE = "https://app.greenlinks.fr/api/single";

type InitialState = {
  loading: boolean;
  Candidats: Candidat[];
  eCandidat: Candidat;
  error: string;
};
const candidat: Candidat = {
  id: "",
  firstname: "",
  lastname: "",
  email: "",
  phonenumber: "",
  Position: "",
  Contract: "ACTIF",
  Status: "CDD",
  Country: "France",
  Region: "",
  skills: [],
  cv: "",
  frontsheet: undefined,
  qualification: undefined,
  notes: [],
  user: [],
  //createdAt: '',
};
const initialState: InitialState = {
  loading: false,
  Candidats: [],
  eCandidat: candidat,
  error: "",
};

export const CreateCandidat = createAsyncThunk<Candidat, Candidat>(
  "candidat/addcandidat",
  async (data, thunkAPI) => {
    try {
      const res = await axios.post(API_URL, data, { withCredentials: true });
      toast.success("Candidat est ajouté");
      return res.data;
    } catch (error) {
      if (
        error.response.data.error ===
        "A candidat with this email address already exists."
      ) {
        toast.error(
          "L'email que vous avez tapez déja existe dans la base de donnée !"
        );
      } else {
        toast.error("Une erreur s'est produite lors de l'ajout de candidat");
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllCandidat = createAsyncThunk<Candidat[]>(
  "candidat/getallcandidat",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get(API_URL, { withCredentials: true });
      return res.data.message;
    } catch (error) {
      toast.error(
        "Un erreur s'est produit lors de l'affichage de la liste des candidats"
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const uploadcvToGoogleDrive = createAsyncThunk(
  "candidat/addcvtoGoogledrive",
  async (resume, thunkAPI) => {
    try {
      const data = new FormData();
      data.append("drive_file", resume);

      const res = await axios.post(API_URL + "uploadToDrive", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Le fichier a été téléversé vers Google Drive");
      return res.data;
    } catch (error) {
      toast.error(
        "Un erreur s'est produit lors de l'upload du cv dans google drive"
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getcandidatbyid = createAsyncThunk<Candidat, string>(
  "candidat/getcandidatbyid",
  async (id, thunkAPI) => {
    try {
      const res = await axios.get(API_URL + id, { withCredentials: true });
      return res.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removecandidatbyid = createAsyncThunk<Candidat[], string>(
  "candidat/deletecandidatbyid",
  async (id, thunkAPI) => {
    try {
      const res = await axios.delete(API_URL + id, { withCredentials: true });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatecandidatbyid = createAsyncThunk<Candidat, string>(
  "candidat/updatecandidatbyid",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await axios.put(API_URL + id, data, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const AffectNoteToCandidate = createAsyncThunk<Candidat[], string>(
  "candidat/addnote",
  async ({ candidateid, data }, thunkAPI) => {
    try {
      const res = await axios.post(
        API_URL + "note/",
        { candidateid, data },
        { withCredentials: true }
      );
      //console.log(res.data)
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllCandidateNotes = createAsyncThunk<Candidat[], string>(
  "candidat/getnote",
  async ({ candidatId }, thunkAPI) => {
    try {
      const res = await axios.get(API_URL + "note/" + { candidatId }, {
        withCredentials: true,
      });
      console.log(res);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletenoteasync = createAsyncThunk<Note[], string>(
  "note/deletenotebyid",
  async (id, ThunkAPI) => {
    try {
      const res = await axios.delete(API_URL + "note/" + id, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return ThunkAPI.rejectWithValue(error);
    }
  }
);

export const parseresumeCandidate = createAsyncThunk(
  "resume/parse",
  async (data, thunkAPI) => {
    try {
      console.log("hi");
      const res = await axios.post(API_PARSE, data, { withCredentials: true });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const candidatSlice = createSlice({
  name: "candidat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCandidat.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllCandidat.fulfilled,
      (state, action: PayloadAction<Candidat[]>) => {
        state.loading = false;
        state.Candidats = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getAllCandidat.rejected, (state, action) => {
      state.loading = false;
      state.Candidats = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(CreateCandidat.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      CreateCandidat.fulfilled,
      (state, action: PayloadAction<Candidat>) => {
        state.loading = false;
        state.eCandidat = action.payload;
        state.error = "";
      }
    );
    builder.addCase(CreateCandidat.rejected, (state, action) => {
      state.loading = false;
      //state.users = []
      state.error = action.payload
        ? action.payload.toString()
        : "Something went wrong";
    });
    builder.addCase(
      getcandidatbyid.fulfilled,
      (state, action: PayloadAction<Candidat>) => {
        state.loading = false;
        state.eCandidat = action.payload;
        state.error = "";
      }
    );
    builder.addCase(removecandidatbyid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      removecandidatbyid.fulfilled,
      (state, action: PayloadAction<Candidat[]>) => {
        state.loading = false;
        state.Candidats = action.payload;
        state.error = "";
      }
    );
    builder.addCase(removecandidatbyid.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload
        ? action.payload.toString()
        : "Something went wrong";
    });
    builder.addCase(
      AffectNoteToCandidate.fulfilled,
      (state, action: PayloadAction<Candidat[]>) => {
        state.loading = false;
        state.Candidats = action.payload;
        state.error = "";
      }
    );
    builder.addCase(AffectNoteToCandidate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(AffectNoteToCandidate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload
        ? action.payload.toString()
        : "Something went wrong";
    });
    builder.addCase(parseresumeCandidate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    //builder.addCase(parseresumeCandidate.)
  },
});

export default candidatSlice.reducer;
