import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Qualification } from '../../interfaces/Candidat/Qualification'
import toast from 'react-hot-toast'
const API_URLQ = 'https://app.greenlinks.fr/api/qualification/'

type InitialState = {
    loading: boolean;
    Qualification?: Qualification;
    error: string
}

const qualification :Qualification = {
    id: '',
    Adresse:'',
    CandidatName:'',
    Email:'',
    Nationalite:'',
    Permis:'',
    Tel:'',
    TelFixe:'',
    Site: '',
    candidat: undefined,
    Q1: '',
    Q2: '',
    Q3: '',
    Q4: '',
    Q5: '',
    Q6: '',
    Q7:'',
    Q8: '',
    Q9: '',
    Q10: '',
    Q11: '',
    Q12: '',
    Q13: '',
    Q14: '',
    Q15: '',
    Q16: '',
    Q17: '',
    Q18: '',
    Q19: '',
    Q20: '',
    Q21: '',
    Q22: '',
    Q23: '',
    Q24: '',
    Q25: '',
    Q26: '',
    Q27: '',
    Q28: '',
    Q29: '',
    Q30: '',
    Q31: '',
    Q32: '',
    Q33: ''
}

const initialState: InitialState = {
    loading: false,
    Qualification: qualification,
    error: ""
}

export const saveQualification = createAsyncThunk<Qualification,Qualification>(
    "qualification/updatequalification",
    async(qualificationForm, thunkAPI)=>{
        try {
            const id = qualificationForm.id
            const res = await axios.put(API_URLQ+id, qualificationForm,{ withCredentials: true })
            //console.log(res.data)
            return res.data
        } catch (error) {
            toast.error("Un erreur s'est produit lors de l'enregistrement du qualification")
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getQualificationbyid = createAsyncThunk<Qualification>(
    "Qualification/getbyid",
    async(id,thunkAPI)=>{
        try {
            const res = await axios.get(API_URLQ+ id, {withCredentials: true})
            return res.data
        } catch (error) {
            toast.error("Un erreur s'est produit lors de l'affichage du donnée de qualification")
            return thunkAPI.rejectWithValue(error);
        }
    }
)

const qualificationSlice = createSlice({
    name: 'qualification',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(saveQualification.pending, (state) => {
            state.loading = true;
            state.error = '';
        })
        .addCase(saveQualification.fulfilled, (state, action: PayloadAction<Qualification>) => {
            state.loading = false;
            state.Qualification= action.payload
            state.error = '';
        })
        .addCase(saveQualification.rejected, (state) => {
            state.loading = false;
            state.error = 'An error occurred while saving the frontsheet.';
        });
    }
})

export default qualificationSlice.reducer
