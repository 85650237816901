import { configureStore } from '@reduxjs/toolkit'
//import { useDispatch } from "react-redux";
//import userSlice from '../features/User/userSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import leadsSlice from '../features/leads/leadSlice'

import columnsReducer from './slices/columns.slice';
import cardsReducer from './slices/cards.slice';

import userReducer from '../features/user/userSlice'
import skillReducer from '../features/skill/skillSlice'
import candidatReducer from '../features/candidat/candidatSlice'
import frontsheetreducer from '../features/candidat/frontsheetSlice'
import qualificatioreducer from '../features/candidat/qualificationSlice'
import clientreducer from '../features/client/clientSlice'
import jobreducer from '../features/jobs/jobSlice'
import processreducer from '../features/process/processSlice'
import statsreducer from '../features/dashboard/statsSlice'
export const store = configureStore({
    reducer: {
        header : headerSlice,
        rightDrawer : rightDrawerSlice,
        modal : modalSlice,
        lead : leadsSlice,
        columns: columnsReducer,
        cards: cardsReducer,
        user: userReducer,
        skill: skillReducer,
        candidat: candidatReducer,
        frontsheet: frontsheetreducer,
        qualification: qualificatioreducer,
        client: clientreducer,
        job : jobreducer,
        process:processreducer,
        stats:statsreducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch