import { Suspense } from 'react'
import {store} from './app/store'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'

import SuspenseContent from './containers/SuspenseContent'
import { Toaster } from 'react-hot-toast'
import "./instrument";

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Suspense fallback={<SuspenseContent />}>
    <Provider store={store}>
		<App />
		<Toaster/>
    </Provider>
    </Suspense>
)
