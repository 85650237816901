
export enum PL{
    ACCEPTE = "Accepte",
    OFFRE = "Offre",
    ENTRETIENTCLIENT = "Entretient Client",
    QUALIFIE = "Qualifie"
} 
export type IProcess = {
    jobId?:string,
    candidateId?:string,
    ProcessLevel:PL,
    RecrutementStep?: []
}