import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { FrontSheet } from '../../interfaces/Candidat/FrontSheet'
import toast from 'react-hot-toast'
const API_URLF = 'https://app.greenlinks.fr/api/frontsheet/'

type InitialState = {
    loading: boolean;
    Frontsheet?: FrontSheet;
    error: string
}

const frontsheet :FrontSheet = {
    id: '',
    candidat:undefined,
    CandidatName: '',
    Certification: '',
    Competence: '',
    CurrentSalary :0,
    SalaryWanted: 0,
    Disponability: '',
    Formations: '',
    Language: '',
    Poste: '',
    Remarque : '',
    StartingDate: '',
    remote: 'Obligatoire'
    //createdAt: '',
}

const initialState: InitialState = {
    loading: false,
    Frontsheet: frontsheet,
    error: ""
}

export const saveFrontsheet = createAsyncThunk<FrontSheet>(
    "frontsheet/updatefrontsheet",
    async(frontsheetForm, thunkAPI)=>{
        try {
            const id = frontsheetForm.id
            const res = await axios.put(API_URLF+id, frontsheetForm,{ withCredentials: true })
            //console.log(res.data)
            return res.data
        } catch (error) {
            toast.error("Un erreur s'est produit lors de l'enregistrement du frontsheet")
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getFronsheetbyid = createAsyncThunk<FrontSheet>(
    "frontsheet/getbyid",
    async(id,thunkAPI)=>{
        try {
            const res = await axios.get(API_URLF+ id, {withCredentials: true})
            return res.data
        } catch (error) {
            toast.error("Un erreur s'est produit lors de l'affichage du donnée de frontsheet")
            return thunkAPI.rejectWithValue(error);
        }
    }
)

const frontsheetSlice = createSlice({
    name: 'frontsheet',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(saveFrontsheet.pending, (state) => {
            state.loading = true;
            state.error = '';
        })
        .addCase(saveFrontsheet.fulfilled, (state, action: PayloadAction<FrontSheet>) => {
            state.loading = false;
            state.Frontsheet= action.payload
            state.error = '';
        })
        .addCase(saveFrontsheet.rejected, (state) => {
            state.loading = false;
            state.error = 'An error occurred while saving the frontsheet.';
        });
    }
})

export default frontsheetSlice.reducer
