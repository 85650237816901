import { useState } from 'react';
import { FcGoogle } from "react-icons/fc";
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { login } from '../../features/user/userSlice';

import { useAppDispatch } from '../../app/hooks';
const SignInSchema = z.object({
    email: z
	.string()
	.nonempty("Veuillez saisir votre email.")
	.email("Veuillez saisir un email valide"),
    password: z
	.string()
	.min(10,"Le mot de passe doit être de 8 caractères minimum ")
	.regex(new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'), "Le mot de passe doit avoir 1 lettre miniscule , 1 lettre majuscule , 1 lettre speciale et un chiffre ."),
	//.regex(),
});

type SignInInput = z.infer<typeof SignInSchema>;

const SignIn = () => {

	const { 
		register,
		handleSubmit,
		formState: {errors}
	} = useForm<SignInInput>({
		resolver:zodResolver(SignInSchema),
		defaultValues: {
			email: '',
			password: '',
		},
	})
	const dispatch = useAppDispatch();
	const [disabled, setDisabled] = useState(false)
	const [show, setShow] = useState(true)
	async function signInClicked(email: string, password: string) {
		const user = {email, password}
		const result = await dispatch(login(user))
		if (result.meta.requestStatus==='fulfilled') {
			await new Promise(resolve => setTimeout(resolve, 2000)); 
			window.location.href= "/app/dashboard"

		}
		
	}
	
	

	async function googleSignInClicked() {
		/*try {
			setDisabled(true)
			const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
				thirdPartyId: "google",
	
				// This is where Google should redirect the user back after login or error.
				// This URL goes on the Google's dashboard as well.
				frontendRedirectURI: "http://localhost:5173/auth/callback/google",
			});
		
			// we redirect the user to google for auth.
			window.location.assign(authUrl);
		} catch (err: any) {
			setDisabled(false)
			if (err.isSuperTokensGeneralError === true) {
				// this may be a custom error message sent from the API by you.
				//window.alert(err.message);
				toast.error(err.message)
			} else {
				toast.error("Oups ! Un problème s'est produit.")
				//window.alert("Oops! Something went wrong.");
			}
		}*/
	}

	
	return (
		<div  >
			
			
			<div className="bg-auto min-h-screen bg-base-200 flex items-center bg-[url('https://res.cloudinary.com/dcdei4osp/image/upload/v1663928607/logo/background_v9tbsw.jpg')]">
			
            <div className="card mx-auto w-full max-w-5xl  shadow-xl opacity-95">
				
                <div className="grid  md:grid-cols-2 grid-cols-1  bg-base-100 rounded-xl">
                <div className=''>
					{/* Landing Intro */}
					<div className="hero min-h-full rounded-l-xl bg-base-200">
						<div className="hero-content py-12">
							<div className="max-w-md">
								<div className="text-center mt-12"><img src="https://res.cloudinary.com/dcdei4osp/image/upload/v1692609330/green-links-logo-simplifie_mljnej.png" alt="Dashwind Admin Template" className="w-80 inline-block"></img></div>
								</div>
							</div>
					</div>
				</div>
					<div className='py-24 px-10'>
                    <h4 className='text-2xl font-semibold mb-2 text-center'>Bonjour  👏</h4>
					
					
					<form onSubmit={handleSubmit((d) => signInClicked(d.email, d.password))}>
						<div className="mb-4">
							{/* Input Email */}
							<div className="form-control w-full mt-4">
								<label className="label">
									<span className="label-text text-base-content ">Email</span>
								</label>
								<div className="input-group">
									<input
										type="email" 
										placeholder="Tapez votre adresse email" 
										className="input  input-bordered w-full "
										{ ...register("email")}
									/>
									<span>
									<svg
										className="fill-current"
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g opacity="0.5">
											<path
												d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z"
												fill=""
											/>
										</g>
									</svg>
									</span>
									
								</div>
							</div>
							{errors?.email?.message && <p className="text-center mt-2 text-error">{errors.email.message}</p>}
							{/* Input Password */}
							<div className="form-control w-full mt-4">
								<label className="label">
									<span className="label-text text-base-content ">Mot de passe</span>
								</label>
								<div className="input-group">
									<input
										type={show ? "password" : "text"}
										placeholder="Tapez votre Mot de passe" 
										className="input  input-bordered w-full "
										{ ...register('password')}
									/>
									<label className="btn btn-square" onClick={()=>setShow(!show)}>
									
									{show ?
										(<svg id="Layer_1" data-name="Layer 1" width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>eye-glyph</title><path d="M320,256a64,64,0,1,1-64-64A64.07,64.07,0,0,1,320,256Zm189.81,9.42C460.86,364.89,363.6,426.67,256,426.67S51.14,364.89,2.19,265.42a21.33,21.33,0,0,1,0-18.83C51.14,147.11,148.4,85.33,256,85.33s204.86,61.78,253.81,161.25A21.33,21.33,0,0,1,509.81,265.42ZM362.67,256A106.67,106.67,0,1,0,256,362.67,106.79,106.79,0,0,0,362.67,256Z"/></svg>)
										:
										(<svg id="Layer_1" data-name="Layer 1" width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>eye-disabled-glyph</title><path d="M409.84,132.33l95.91-95.91A21.33,21.33,0,1,0,475.58,6.25L6.25,475.58a21.33,21.33,0,1,0,30.17,30.17L140.77,401.4A275.84,275.84,0,0,0,256,426.67c107.6,0,204.85-61.78,253.81-161.25a21.33,21.33,0,0,0,0-18.83A291,291,0,0,0,409.84,132.33ZM256,362.67a105.78,105.78,0,0,1-58.7-17.8l31.21-31.21A63.29,63.29,0,0,0,256,320a64.07,64.07,0,0,0,64-64,63.28,63.28,0,0,0-6.34-27.49l31.21-31.21A106.45,106.45,0,0,1,256,362.67ZM2.19,265.42a21.33,21.33,0,0,1,0-18.83C51.15,147.11,148.4,85.33,256,85.33a277,277,0,0,1,70.4,9.22l-55.88,55.88A105.9,105.9,0,0,0,150.44,270.52L67.88,353.08A295.2,295.2,0,0,1,2.19,265.42Z"/></svg>)
									}
									</label>
									<span >
									<svg
										className="fill-current"
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g opacity="0.5">
										<path
											d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
											fill=""
										/>
										<path
											d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
											fill=""
										/>
										</g>
									</svg>
									</span>
									
								</div>
							</div>
							{errors?.password?.message && <p className="text-center mt-2 text-error">{errors.password.message}</p>}

						</div>
							
						
						<div className='text-right text-primary'>
							<Link to="/forgot-password">
								<span className="text-sm  inline-block  hover:text-primary hover:underline hover:cursor-pointer transition duration-200">Mot de passe oublier?</span></Link>
                        </div>
						
						

						<button type="submit" className={"btn mt-5 w-full btn-primary"}>Se Connecter</button>
						{/* CREATE ACCOUNT */}
						
						
						<div className="my-6 flex items-center  gap-3">
						<div className="h-px w-full bg-base-100 dark:bg-gray-700" />
							<p className="text-base text-primary"> ou </p>
						<div className="h-px w-full bg-base-100 dark:bg-gray-700" />
					</div>

					</form>	


					<button className="btn mt-5 w-full btn-circle btn-group btn-primary" onClick={googleSignInClicked} disabled={disabled} >
						<div className="rounded-full text-xl">
							<FcGoogle />
						</div>
						<h5 className="text-sm font-medium text-navy-700 dark:text-white">
							Continuer avec Google
						</h5>
					</button>
					
					
					
					</div>
						
				</div>
				</div>
			</div>
        </div>
	);
};

export default SignIn;
