import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IProcess, PL } from '../../interfaces/Process/IProcess'
//import { Skill } from '../../interfaces/Skill/Skill'
import toast from 'react-hot-toast'
const API_URL = 'https://app.greenlinks.fr/api/process/'

type InitialState = {
    loading: boolean;
    ProcessList: IProcess[];
    eProcess : IProcess;
    error: string
}

const process : IProcess = {
    candidateId:"",
    jobId:"",
    ProcessLevel:PL.OFFRE
}
const initialState: InitialState = {
    loading: false,
    ProcessList: [],
    eProcess: process,
    error: ""
}

export const CreateProcess = createAsyncThunk<IProcess, IProcess>(
    "process/addprocess",
    async (data, thunkAPI) => {
        try {
            //console.log(data)
            const res = await axios.post(API_URL, data, { withCredentials: true });
            toast.success("Process est ajouté");
            return res.data;
        } catch (error) {
            toast.error("Une erreur s'est produite lors de l'ajout de client");
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getAllProcess = createAsyncThunk<IProcess[]>(
    "process/getallprocess",
    async (_, thunkAPI)=>{
        try {
            const res = await axios.get(API_URL,{withCredentials: true})
            return res.data
        } catch (error) {
            toast.error("Un erreur s'est produit lors de l'affichage de la liste des jobs")
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getprocessbyid = createAsyncThunk<IProcess, string>(
    "process/getprocessbyid",
    async(id, thunkAPI)=>{
        try {
            const res = await axios.get(API_URL+id, {withCredentials: true})
            return res.data
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const removejobbyid = createAsyncThunk<IProcess[],string>(
    "process/deleteprocessbyid",
    async(id,thunkAPI)=>{
        try {
            const res = await axios.delete(API_URL+id,{withCredentials: true})
            toast.success("Supprimer !")
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
    
)

export const updateprocessbyid = createAsyncThunk<IProcess, string>(
    "process/updateprocessbyid",async({id,data},thunkAPI)=>{
        try {
            const res = await axios.put(API_URL+"updatestate/"+id,{data}, {withCredentials:true})
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

export const updaterecrutement = createAsyncThunk<string>(
    "recrutement/updatebyid",async({stepId,date},thunkAPI)=>{
        try {
            const id:string= stepId
            console.log(id)
            console.log(date)
            const res = await axios.put(API_URL+"recrutementstep/"+id,{date}, {withCredentials:true})
          
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProcess.pending, state => {
            state.loading = true
        })
        builder.addCase(getAllProcess.fulfilled,
            (state, action: PayloadAction<IProcess[]>) => {
                state.loading = false
                state.ProcessList = action.payload
                state.error = ''
            }
        )
        builder.addCase(getAllProcess.rejected,
            (state,action) => {
                state.loading = false
                state.ProcessList = []
                state.error = action.error.message || 'Something went wrong'
            }
        )
        builder.addCase(CreateProcess.pending, state => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(CreateProcess.fulfilled,
            (state, action: PayloadAction<IProcess>) => {
                state.loading = false
                state.eProcess = action.payload
                state.error = ''
            }
        )
        builder.addCase(CreateProcess.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload ? action.payload.toString() : 'Something went wrong';
        })
        builder.addCase(getprocessbyid.fulfilled,
            (state, action:PayloadAction<IProcess>)=>{
            state.loading= false
            state.eProcess=action.payload
            state.error= ""
        })
        

    }
})

export default processSlice.reducer
