import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IClient } from '../../interfaces/Client/Client'
import toast from 'react-hot-toast'
import { IRecruitmentStep } from '../../interfaces/Client/RecrutementStep'
const API_URL = 'https://app.greenlinks.fr/api/client/'

const InitialRecrutementStep : IRecruitmentStep = {
    stepDescription : '',
    date: new Date().toISOString(),
    debrief: ''
}


type InitialState = {
    loading: boolean;
    Clients: IClient[];
    eClient : IClient;
    error: string
}

const client : IClient = {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber1: '',
    phoneNumber2: '',
    country: '',
    state:'',
    recrutementStep1:InitialRecrutementStep,
    recrutementStep2:InitialRecrutementStep
    //createdAt: '',
}
const initialState: InitialState = {
    loading: false,
    Clients: [],
    eClient: client,
    error: ""
}

export const CreateClient = createAsyncThunk<IClient, IClient>(
    "client/addclient",
    async (data, thunkAPI) => {
        try {
            const res = await axios.post(API_URL, data, { withCredentials: true });
            toast.success("Candidat est ajouté");
            return res.data;
        } catch (error) {
            if(error.response.data.error ==="A client with this email address already exists."){
                toast.error("L'email que vous avez tapez déja existe dans la base de donnée !")
            }else{
                toast.error("Une erreur s'est produite lors de l'ajout de client");
            }
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getAllClient = createAsyncThunk<IClient[]>(
    "client/getallclient",
    async (_, thunkAPI)=>{
        try {
            const res = await axios.get(API_URL,{withCredentials: true})
            return res.data
        } catch (error) {
            toast.error("Un erreur s'est produit lors de l'affichage de la liste des candidats")
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getclientbyid = createAsyncThunk<IClient, string>(
    "client/getclientbyid",
    async(id, thunkAPI)=>{
        try {
            const res = await axios.get(API_URL+id, {withCredentials: true})
            return res.data
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const removeclientbyid = createAsyncThunk<IClient[],string>(
    "client/deleteclientbyid",
    async(id,thunkAPI)=>{
        try {
            const res = await axios.delete(API_URL+id,{withCredentials: true})
            toast.success("Supprimer !")
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
    
)

export const updateclientbyid = createAsyncThunk<IClient, string>(
    "client/updateclientbyid",async({id,data},thunkAPI)=>{
        try {
            //console.log(data)
            const res = await axios.put(API_URL+id,data, {withCredentials:true})
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(getAllClient.pending, state => {
            state.loading = true
        })
        builder.addCase(getAllClient.fulfilled,
            (state, action: PayloadAction<Candidat[]>) => {
                state.loading = false
                state.Clients = action.payload
                state.error = ''
            }
        )
        builder.addCase(getAllClient.rejected,
            (state,action) => {
                state.loading = false
                state.Clients = []
                state.error = action.error.message || 'Something went wrong'
            }
        )
        builder.addCase(CreateClient.pending, state => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(CreateClient.fulfilled,
            (state, action: PayloadAction<IClient>) => {
                state.loading = false
                state.eClient = action.payload
                state.error = ''
            }
        )
        builder.addCase(CreateClient.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload ? action.payload.toString() : 'Something went wrong';
        })
        builder.addCase(getclientbyid.fulfilled,
            (state, action:PayloadAction<IClient>)=>{
            state.loading= false
            state.eClient=action.payload
            state.error= ""
        })
        

    }
})

export default clientSlice.reducer
