import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IStats } from '../../interfaces/Stats/IStats'
//import { Skill } from '../../interfaces/Skill/Skill'
import toast from 'react-hot-toast'
const API_URL = 'https://app.greenlinks.fr/api/stats/'

type InitialState = {
    loading: boolean;
    Stats: IStats;
    error: string
}

const stat : IStats = {
    CandidateCount: 0, 
    UserCount:0, 
    ProcessCount: {
        ProcessA:0, ProcessEC:0, ProcessO:0, ProcessQ:0
    }
}
const initialState: InitialState = {
    loading: false,
    Stats: stat,
    error: ""
}

export const getStat = createAsyncThunk<IStats>(
    "stat/getstats",
    async (_, thunkAPI) => {
        try {
            const res = await axios.get(API_URL, { withCredentials: true });
            return res.data.DBStats;
        } catch (error) {
            toast.error("Une erreur s'est produite lors de le calcul des statistiques");
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const statSlice = createSlice({
    name: 'stat',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(getStat.pending, state => {
            state.loading = true
        })
        builder.addCase(getStat.fulfilled,
            (state, action: PayloadAction<IStats>) => {
                state.loading = false
                state.Stats = action.payload
                state.error = ''
            }
        )
        builder.addCase(getStat.rejected,
            (state,action) => {
                state.loading = false
                state.Stats = stat
                state.error = action.error.message || 'Something went wrong'
            }
        )
    }
})
export default statSlice.reducer
