import React, { useState, useEffect} from 'react'
import { Button } from '../../components/Modal/styles';
import toast from 'react-hot-toast';

const sleep = async (milliseconds) => {
    await new Promise(resolve => {
        return setTimeout(resolve, milliseconds)
    });
};


const ActivateAccount = () => {
    const [enter, setEnter] = useState(false)

    async function consumeVerificationCode() {
        /*try {
            console.log("entered")
            let response = await verifyEmail();
            console.log(response)
            if (response.status === "EMAIL_VERIFICATION_INVALID_TOKEN_ERROR") {
                // This can happen if the verification code is expired or invalid.
                // You should ask the user to retry
                window.alert("Oops! Seems like the verification link expired. Please try again")
                window.location.assign("/auth/verify-email") // back to the email sending screen.
            } else {
                // email was verified successfully.
                //window.location.assign("/home")
                toast.success("Vote compte est active")
                toast.loading("Redirection vers page principale ...")
                await sleep(2000);
                window.location.assign("/app")
            }
        } catch (err: any) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
            } else {
                window.alert("Oops! Something went wrong.");
            }
        }*/
    }

    /*useEffect(() => {
        if(enter!)

        {
            setEnter(true)
            consumeVerificationCode()

        }
    },[enter])*/

  return (
    <div>
      <Button onClick={consumeVerificationCode} > ACTIVATE ACCOUNT </Button>
    </div>
  )
}

export default ActivateAccount
