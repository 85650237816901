import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Skill } from '../../interfaces/Skill/Skill'
import toast from 'react-hot-toast'
const API_URL = 'https://app.greenlinks.fr/api/skill/'

type InitialState = {
    loading: boolean;
    skills: Skill[];
    sskill : Skill;
    error: string
}
const skill : Skill = {
    id: '',
    nom: '',
    tags: []
}

const initialState: InitialState = {
    loading: false,
    skills: [],
    sskill: skill,
    error: ""
}

export const fetchSkills = createAsyncThunk<Skill[]>(
    "skills/getskills",
    async (_,thunkAPI)=>{
        try {
            const res = await axios.get(API_URL, {withCredentials: true})
            //console.log(res.data)
            return res.data.listofskills
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const GetSkillById = createAsyncThunk<Skill>(
    "skill/getskillbyid",
    async (id,thunkAPI)=>{
        try {
            const res = await axios.get(API_URL+`${id}`,{withCredentials: true})
            
            return res.data
        } catch (error:any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const CreateSkill = createAsyncThunk<Skill>(
    "skill/addskill",
    async (data,thunkAPI)=>{
        try {
            const res = await axios.post(API_URL,data,{withCredentials: true})
            toast.success("Compétence ajouter avec succées")
            return res.data
        } catch (error:any) {
            if(error.response.data.error === "Skill already exist."){
                toast.error("La Compétence déja existe dans la base de donneé")
            }
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const updateSkill = createAsyncThunk<Skill>(
    "skill/update",
    async({data, id}, thunkAPI)=>{
        try {
            
            const res = await axios.put(API_URL+id, data,{withCredentials: true})
            toast.success("Compétence a été modifier avec succes")
            return res.data
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const deleteSkill = createAsyncThunk<Skill>(
    "skill/delete",
    async( id, thunkAPI)=>{
        try {
            const res = await axios.delete(API_URL+id,{withCredentials: true})
            toast.success("Compétence a été supprimer avec succées")
            return res.data
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

const skillSlice = createSlice({
    name: 'skill',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSkills.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchSkills.fulfilled,
            (state, action: PayloadAction<Skill[]>) => {
                state.loading = false
                state.skills = action.payload
                state.error = ''
            }
        )
        builder.addCase(fetchSkills.rejected, (state, action) => {
            state.loading = false
            state.skills = []
            state.error = action.error.message || 'Something went wrong'
        })
        builder.addCase(GetSkillById.pending, state=>{
            state.loading = true
        })
        builder.addCase(GetSkillById.fulfilled,
        (state, action: PayloadAction<Skill>) => {
            state.loading = false
            state.sskill = action.payload
            state.error = ''
        })
        builder.addCase(GetSkillById.rejected, (state, action) => {
            state.loading = false
            state.sskill = skill
            state.error = action.error.message || 'Something went wrong'
        })
        builder.addCase(CreateSkill.pending, state=>{
            state.loading = true
        })
        builder.addCase(CreateSkill.fulfilled,
            (state, action: PayloadAction<Skill>) => {
            state.loading = false
            state.sskill = action.payload
            state.error = ''
        })
        builder.addCase(CreateSkill.rejected, (state, action) => {
            state.loading = false
            state.skills = []
            state.error = action.error.message || 'Something went wrong'
        })
        
    }
})
export default skillSlice.reducer
