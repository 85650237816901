import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../interfaces/User/User'
import toast from 'react-hot-toast'
const API_URL = 'https://app.greenlinks.fr/api/user/'
const IMG_URL = "https://app.greenlinks.fr/api/upload_avatar"

type InitialState = {
    loading: boolean;
    users: User[];
    currentuser: User;
    suser : User;
    isLogged: boolean;
    error: string
}

const user : User = {
    id: '',
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    phonenumber: '',
    emailVerified: '',
    image: '',
}

const initialState: InitialState = {
    loading: false,
    users: [],
    currentuser: user,
    suser: user,
    isLogged: false,
    error: ""
}

export const fetchUsers = createAsyncThunk<User[]>(
    "users/getusers",
    async (_,thunkAPI)=>{
        try {
            const res = await axios.get(API_URL+"list/users",{withCredentials: true})
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const login = createAsyncThunk<User>(
    "users/login",
    async(data,thunkAPI)=>{
        try {
            const res = await axios.post(API_URL+"login",data,{withCredentials: true})
            toast.success("Successfully Logged In");
            //window.location.href= "/app/dashboard"
            return res.data
        } catch (error:any) {
            if(error.response.data.error==="Invalid credentials"){
                toast.error("Vote mots de passe ou adresse mail sont invalide. ")
            }else if(error.response.data.error==="You need to verify your acccount, Check your email" ){
                toast.error("Veuiller consulter votre boite email.")
            }else if (error.response.data.error==="contact admin to affect role."){
                toast.error("Contacter l'administrateur pour vous affecter un role.")
            }
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const getisLogged = createAsyncThunk<User>(
    "user/islogged",
    async (_,thunkAPI)=>{
        try {
            const res = await axios.get(API_URL,{withCredentials: true})
            //console.log(res.data)
            return res.data
        } catch (error:any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const getUserLogged = createAsyncThunk<User>(
    "user/getuser",
    async (_,thunkAPI)=>{
        try {
            const result = await axios.get(API_URL,{withCredentials: true})
            
            const res = await axios.get(API_URL+ result.data.id,{withCredentials: true})
            //console.log(res.data)
            return res.data
        } catch (error:any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const updateUser = createAsyncThunk<User>(
    "user/update",
    async({data, id}, thunkAPI)=>{
        try {
            
            const res = await axios.put(API_URL+id, data,{withCredentials: true})
            toast.success("Profil a été mis a jour avec succes")
            return res.data
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const UploadToCloudinary = createAsyncThunk(
    "user/uploadavatar",
    async(file, thunkAPI)=>{
        try {
            const res = await axios.post(IMG_URL, file,{
                headers: {'content-type': 'multipart/form-data'}
            })
            //console.log("res.data.file :",res.data.file)
            toast.success("Fichier est téléversé vers le cloud")
            
            return res.data.file
        } catch (error:any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const logout = createAsyncThunk<User>(
    "user/logout",
    async(_,thunkAPI)=>{
        try {
            const res = await axios.post(API_URL+"logout",null, {
                withCredentials: true, // Include credentials for session-based authentication
            })
            
            return res.data
        } catch (error:any) {

            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const UpdateUserAvatar = createAsyncThunk(
    "user/updateavatar",
    async(imgurl, thunkAPI)=>{
        try {
            const res = await axios.put(API_URL+"updateavatar",{imgurl},{withCredentials: true})
            toast.success("Modification réussite")
            return res.data.file
        } catch (error:any) {
            toast.error("error")
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const GetUserById = createAsyncThunk<User>(
    "user/getuserbyid",
    async (id,thunkAPI)=>{
        try {
            const res = await axios.get(API_URL+`${id}`,{withCredentials: true})
            
            return res.data
        } catch (error:any) {
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const CreateUser = createAsyncThunk<User[]>(
    "user/createuser",
    async (data,thunkAPI)=>{
        try {
            const res = await axios.post(API_URL+"createuser",data,{withCredentials: true})
            toast.success("Utilisateur ajouter avec succés")
            return res.data
        } catch (error:any) {
            toast.error("Erreur ")
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchUsers.fulfilled,
            (state, action: PayloadAction<User[]>) => {
                state.loading = false
                state.users = action.payload
                state.error = ''
            }
        )
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.loading = false
            state.users = []
            state.error = action.error.message || 'Something went wrong'
        })
        builder.addCase(getisLogged.pending, state => {
            state.loading = true
        })
        builder.addCase(getisLogged.fulfilled,
            (state) => {
                state.loading = false
                //state.currentuser = action.payload
                state.isLogged = true
                state.error = ''
            }
        )
        builder.addCase(getisLogged.rejected, (state, action) => {
            state.loading = false
            state.isLogged=false
            state.error = action.error.message || 'Something went wrong'
        })
        builder.addCase(getUserLogged.fulfilled,
            (state, action: PayloadAction<User>) => {
                state.loading = false
                state.currentuser = action.payload
                state.isLogged = true
                state.error = ''
            }
        )
        builder.addCase(GetUserById.pending, state => {
            state.loading = true
        })
        builder.addCase(GetUserById.fulfilled,
            (state, action: PayloadAction<User>) => {
                state.loading = false
                state.suser = action.payload
                state.error = ''
            }
        )
        builder.addCase(updateUser.fulfilled,(state, action: PayloadAction<User>) => {
            state.loading = false
            state.suser = action.payload
            state.error = ''
        })
        /*builder.addCase(fetchUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.users = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });*/
        /*builder.addCase(getGameById.pending, (state) => {
            state.loading = true;            
        });
        builder.addCase(getGameById.fulfilled, (state, action) => {
            state.singleGame = action.payload;
            state.loading = false;
        });
        builder.addCase(updateGame.fulfilled, (state, action) => { 
            state.singleGame = action.payload;
        });*/
        // builder.addCase(deleteGame.fulfilled, (state, action) => {
        //     state.message
        // })
    }
})

export default userSlice.reducer
