import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IJob } from '../../interfaces/Jobs/Jobs'
//import { Skill } from '../../interfaces/Skill/Skill'
import toast from 'react-hot-toast'
const API_URL = 'https://app.greenlinks.fr/api/jobs/'

type InitialState = {
    loading: boolean;
    Jobs: IJob[];
    eJob : IJob;
    error: string
}

const job : IJob = {
    id:"",
    title: "",
    salary: "",
    country: "",
    city: "",
    region: "",
    remote: "",
    skills: []
}
const initialState: InitialState = {
    loading: false,
    Jobs: [],
    eJob: job,
    error: ""
}

export const CreateJob = createAsyncThunk<IJob, IJob>(
    "job/addjob",
    async (data, thunkAPI) => {
        try {
            //console.log(data)
            const res = await axios.post(API_URL, data, { withCredentials: true });
            toast.success("Job est ajouté");
            return res.data;
        } catch (error) {
            toast.error("Une erreur s'est produite lors de l'ajout de client");
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getAllJob = createAsyncThunk<IJob[]>(
    "job/getalljob",
    async (_, thunkAPI)=>{
        try {
            const res = await axios.get(API_URL,{withCredentials: true})
            return res.data
        } catch (error) {
            toast.error("Un erreur s'est produit lors de l'affichage de la liste des jobs")
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getjobbyid = createAsyncThunk<IJob, string>(
    "job/getjobbyid",
    async(id, thunkAPI)=>{
        try {
            const res = await axios.get(API_URL+id, {withCredentials: true})
            return res.data
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const removejobbyid = createAsyncThunk<IJob[],string>(
    "job/deletejobbyid",
    async(id,thunkAPI)=>{
        try {
            const res = await axios.delete(API_URL+id,{withCredentials: true})
            toast.success("Supprimer !")
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
    
)

export const updatejobbyid = createAsyncThunk<IJob, string>(
    "job/updatejobbyid",async({id,data},thunkAPI)=>{
        try {
            console.log(data)
            console.log(id)
            const res = await axios.put(API_URL+id,data, {withCredentials:true})
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(getAllJob.pending, state => {
            state.loading = true
        })
        builder.addCase(getAllJob.fulfilled,
            (state, action: PayloadAction<IJob[]>) => {
                state.loading = false
                state.Jobs = action.payload
                state.error = ''
            }
        )
        builder.addCase(getAllJob.rejected,
            (state,action) => {
                state.loading = false
                state.Jobs = []
                state.error = action.error.message || 'Something went wrong'
            }
        )
        builder.addCase(CreateJob.pending, state => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(CreateJob.fulfilled,
            (state, action: PayloadAction<IJob>) => {
                state.loading = false
                state.eJob = action.payload
                state.error = ''
            }
        )
        builder.addCase(CreateJob.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload ? action.payload.toString() : 'Something went wrong';
        })
        builder.addCase(getjobbyid.fulfilled,
            (state, action:PayloadAction<IJob>)=>{
            state.loading= false
            state.eJob=action.payload
            state.error= ""
        })
        

    }
})

export default jobSlice.reducer
